const BusinessProfileTypeString: Function = (type: string, router: any) => {
	let typeString: string = "";

	switch (type) {
		case "landowner":
			/* Owner */

			typeString = router.locale === "ar-AE" ? "مالك الأرض" : router.locale === "kn-IN" ? "ಭೂಮಿಯ ಮಾಲಿಕ" : "Owner";
			break;

		case "professional":
			/* Service Professional */

			typeString =
				router.locale === "ar-AE"
					? "محترف الخدمة"
					: router.locale === "kn-IN"
						? "ಸೇವೆ ವಿಶೇಷಜ್ಞ"
						: "Service Professional";
			break;

		case "developer":
			/* Builder */

			typeString = router.locale === "ar-AE" ? "مطور" : router.locale === "kn-IN" ? "ಡೆವಲಪರ್" : "Builder";
			break;

		case "agent":
			/* Agent */

			typeString =
				router.locale === "ar-AE" ? "وكيل عقاري" : router.locale === "kn-IN" ? "ರಿಯಲ್ ಎಸ್ಟೇಟ್ ಏಜೆಂಟ್" : "Agent";
			break;

		default:
			typeString = "";
			break;
	}

	return typeString;
};

export default BusinessProfileTypeString;
